import React, { ReactNode, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
    SearchSuggestionsMainContent,
    SearchSuggestionsWrapper,
    StyledList,
    StyledListElement,
    StyledSuggestionLink,
    StyledSuggestionSection,
    StyledSuggetionTexts,
    StyledSuggetionTitle,
    SuggestionGridShowAllContainer,
} from '../shared/styles';
import { useTranslation } from '$hooks';
import {
    IHelloRetailBlogPost,
    IHelloRetailCategory,
    IHelloRetailSearchWordSuggestion,
    IHelloRetailSitePage,
    IMappedHelloRetailProduct,
} from '~/modules/search/interfaces/suggestion';
import { SuggestionProduct } from '../suggestion-product';
import { SvgIcon } from '$components/elements/svg-icon';
import { Text } from '$components/elements/text';
import { formatString, mq } from '$lib/helpers';
import { SuggestionSearchWord } from '../suggestion-search-word';
import { RouteKeys, useCMSRoute } from '~/store/routes';
import { Flex } from '$components/layouts';
import { Link } from '$components/elements/link';
import { SearchContentTypes } from '~/modules/search';
import { FILTER_KEY_MODE } from '~/modules/filter/helpers';
import { trackHelloRetailClick } from '$services/hello-retail-tracking.service';
import { ellipsis } from '$lib/style-helpers';
import { ShowAllLink, SuggestionTabs } from './components';
import { suggestionTab } from './components/suggestion-tabs';
import { Button } from '$components/elements/button';
import { ISearchSerieResult } from '$models';
import { clearLocalStorage, getLocalStorage, setLocalStorage } from '$services/local-storage';
import { LocalStorageKeys } from '$constants';
import { SuggestionSerie } from '../suggestion-serie';
import { useSearchHistory } from '~/modules/search/hooks/use-search-history.hook';

type Variant = 'plain' | 'normal';

type Props = {
    variant?: Variant;
    productResults?: IMappedHelloRetailProduct[];
    articleResults?: IHelloRetailBlogPost[];
    categoryResults?: IHelloRetailCategory[];
    // eslint-disable-next-line react/no-unused-prop-types
    storeResults?: IHelloRetailSitePage[];
    searchWordResults?: IHelloRetailSearchWordSuggestion[];
    searchTerm?: string;
    focusIndex?: number;
    sectionIndex?: number;
    resultCount?: { [key in SearchContentTypes]?: number };
    productFacets?: ReactNode;
    isLoadingMore?: boolean;
    setIndexes?: (sectionIndex: number, focusIndex: number) => void;
    onCloseOverlay?: () => void;
    onLoadMore?: (type: SearchContentTypes, isLocal?: boolean) => void;
    onResetSize?: () => void;
    onSetCurrentTab?: (tab: suggestionTab) => void;
    serieResults?: ISearchSerieResult;
    withScrollRestoration?: boolean;
    hasActiveFacets?: boolean;
    initialTab?: string;
    resetFilters?: () => void;
    isDesktop?: boolean;
    isSearchPage?: boolean;
    onMobileLinkSearch?: (searchQuery: string) => void;
};

export const SuggestionContent = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
    const {
        variant = 'normal',
        articleResults,
        productResults,
        categoryResults,
        searchWordResults,
        searchTerm,
        focusIndex,
        sectionIndex,
        resultCount,
        productFacets,
        isLoadingMore,
        onCloseOverlay,
        setIndexes,
        onLoadMore,
        onResetSize,
        onSetCurrentTab,
        serieResults,
        withScrollRestoration,
        hasActiveFacets,
        resetFilters,
        initialTab,
        isDesktop,
        isSearchPage,
        onMobileLinkSearch,
    } = props;
    const { translate } = useTranslation();
    const { routes } = useCMSRoute();
    const [selectedTab, setSelectedTab] = useState<suggestionTab>((initialTab as suggestionTab) || 'products');
    const ref = useRef<HTMLInputElement>(null);
    const productsRef = useRef<HTMLUListElement>(null);
    const seriesRef = useRef<HTMLDivElement>(null);
    const categoriesRef = useRef<HTMLDivElement>(null);
    const articlesRef = useRef<HTMLDivElement>(null);
    useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement);
    const [currentSearch, setCurrentSearch] = useState(searchTerm);
    const { onAddHistoryItem } = useSearchHistory();

    const getSearchWordUrl = (searchWord?: string) => {
        const searchResultUrl = routes[RouteKeys.Search];
        if (!searchResultUrl || !searchWord) {
            return undefined;
        }
        return `${searchResultUrl}?Search=${encodeURIComponent(searchWord)}`;
    };

    const getTypeSearchUrl = (type: SearchContentTypes) => {
        const searchResultUrl = routes[RouteKeys.Search];
        if (!searchResultUrl || !searchTerm) {
            return undefined;
        }
        return `${searchResultUrl}?Search=${encodeURIComponent(searchTerm)}${
            type && type !== SearchContentTypes.Products ? `&${FILTER_KEY_MODE}=${type}` : ''
        }`;
    };

    const handleClick = async (source?: string) => {
        handleScrollRestoration();
        if (!source) {
            onCloseOverlay?.();
            return;
        }
        trackHelloRetailClick(source);
        onCloseOverlay?.();
    };

    const handleScrollRestoration = () => {
        if (withScrollRestoration) {
            setLocalStorage(LocalStorageKeys.SearchSuggestionPosition, ref.current?.scrollTop);
        }
    };

    useEffect(() => {
        if (!withScrollRestoration) {
            clearLocalStorage(LocalStorageKeys.SearchSuggestionPosition);
            return;
        }
        const scroll = async () => {
            const scrollTop = await getLocalStorage<number>(LocalStorageKeys.SearchSuggestionPosition);
            if (scrollTop) {
                ref.current?.scrollTo(0, scrollTop);
                clearLocalStorage(LocalStorageKeys.SearchSuggestionPosition);
            }
        };
        scroll();
    }, []);

    useEffect(() => {
        if (searchTerm !== currentSearch) {
            setCurrentSearch(searchTerm);
            resetScroll();
        }
    }, [searchTerm]);

    const resetScroll = () => {
        if (ref?.current) {
            ref.current.scrollTo({ top: 0, behavior: 'instant' as ScrollBehavior });
        }
    };

    function scrollToProducts() {
        if (productsRef?.current) {
            productsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    function scrollToSeries() {
        if (seriesRef?.current) {
            seriesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    function scrollToCategories() {
        if (categoriesRef?.current) {
            categoriesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
    function scrollToArticles() {
        if (articlesRef?.current) {
            articlesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    function onShowAllClick() {
        if (searchTerm) {
            onAddHistoryItem(searchTerm);
        }
        onCloseOverlay?.();
    }

    function onMobileSuggestionLink(event: React.MouseEvent<Element, MouseEvent>, search?: string, source?: string) {
        event.preventDefault();
        if (source) {
            trackHelloRetailClick(source);
        }
        if (!search) {
            return;
        }
        onMobileLinkSearch?.(search);
    }

    const filteredSearchWordSuggestions = useMemo(() => {
        return searchWordResults?.filter((word) => word.title?.toLowerCase() !== searchTerm?.toLowerCase());
    }, [searchWordResults, searchTerm]);

    return (
        <SearchSuggestionsWrapper variant={variant}>
            <SuggestionTabs
                selectedTab={selectedTab}
                setSelectedTab={(tab) => {
                    setSelectedTab(tab);
                    onSetCurrentTab?.(tab);
                    onResetSize?.();
                    resetScroll();
                }}
            />
            <SearchSuggestionsMainContent ref={ref}>
                <>
                    {!!filteredSearchWordSuggestions?.length && (
                        <StyledList withBorder>
                            <Text size="12px" fontWeight="Medium" as="p" bottomMargin="2">
                                {translate('search.headerSearch.sectionHeadlines.searchWords')}
                            </Text>
                            {filteredSearchWordSuggestions?.slice(0, 3).map(({ title, trackingCode }, i) => (
                                <SearchWordItem
                                    onFocus={() => setIndexes?.(i, 0)}
                                    onMouseEnter={() => setIndexes?.(i, 0)}
                                    key={(title || '') + i}
                                    state={sectionIndex === 0 && focusIndex === i ? 'active' : ''}
                                >
                                    <StyledSuggestionLink
                                        href={getSearchWordUrl(title)}
                                        onClick={(event) => {
                                            if (isDesktop) {
                                                handleClick(trackingCode);
                                            } else {
                                                onMobileSuggestionLink(event, title, trackingCode);
                                            }
                                            onAddHistoryItem(title);
                                        }}
                                        contentWidth
                                        replace={isDesktop && isSearchPage}
                                    >
                                        <SvgIcon size={20} svg="loupe" />
                                        <StyledSuggetionTexts>
                                            <SuggestionSearchWord searchWord={searchTerm} title={title} />
                                        </StyledSuggetionTexts>
                                    </StyledSuggestionLink>
                                </SearchWordItem>
                            ))}
                        </StyledList>
                    )}

                    {selectedTab === 'products' && (
                        <StyledSuggestionSection>
                            {productFacets}
                            <HeaderContainer alignItems="center" justifyContent="space-between">
                                {isDesktop ? (
                                    <>
                                        <Flex alignItems="center" gap="small">
                                            <StyledSuggetionTitle size="16px" fontWeight="Medium" as="p">
                                                {translate('search.headerSearch.sectionHeadlines.products')}
                                            </StyledSuggetionTitle>
                                            {hasActiveFacets && (
                                                <ResetFacets onClick={resetFilters} variant="link">
                                                    {translate('search.filterAndFacets.resetFilter')}
                                                </ResetFacets>
                                            )}
                                        </Flex>
                                        {!!resultCount?.[SearchContentTypes.Products] && (
                                            <ShowAllLink
                                                href={getTypeSearchUrl(SearchContentTypes.Products)}
                                                onClick={onShowAllClick}
                                                shouldReplace={isDesktop && isSearchPage}
                                                text={`${formatString(
                                                    translate('search.filterAndFacets.expectedResults'),
                                                    resultCount[SearchContentTypes.Products] || 0
                                                )} `}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <HeaderSummary
                                            alignItems="center"
                                            justifyContent="center"
                                            gap="text"
                                            wrap="wrap"
                                        >
                                            <Text>{translate('search.headerSearch.search')}</Text>
                                            <Text fontWeight="Bold">{`"${decodeURIComponent(searchTerm || '')}"`}</Text>
                                            <Text>{translate('search.headerSearch.match')}</Text>
                                            <Text fontWeight="Bold">{resultCount?.[SearchContentTypes.Products]}</Text>
                                            <ScrollButton variant="link" onClick={scrollToProducts}>
                                                <Text variant="bodyLarge">{translate('search.tabs.products')}</Text>
                                            </ScrollButton>
                                            {!!resultCount?.[SearchContentTypes.Series] && !hasActiveFacets && (
                                                <>
                                                    <Text>{translate('search.headerSearch.and')}</Text>
                                                    <SeriesCount fontWeight="Bold">
                                                        {resultCount?.[SearchContentTypes.Series]}
                                                    </SeriesCount>
                                                    <ScrollButton variant="link" onClick={scrollToSeries}>
                                                        <Text variant="bodyLarge">
                                                            {translate('search.tabs.series')}
                                                        </Text>
                                                    </ScrollButton>
                                                </>
                                            )}
                                        </HeaderSummary>
                                    </>
                                )}
                            </HeaderContainer>
                            <StyledList grid ref={productsRef}>
                                {productResults?.map?.((suggestion, i) => (
                                    <SuggestionProduct
                                        key={suggestion.productNumber}
                                        product={suggestion}
                                        index={i}
                                        focusIndex={focusIndex}
                                        sectionIndex={sectionIndex}
                                        setIndexes={setIndexes}
                                        onClick={handleScrollRestoration}
                                    />
                                ))}
                                {!!productResults?.length && !!resultCount?.[SearchContentTypes.Products] && (
                                    <SuggestionGridShowAllContainer>
                                        <ShowAllLink
                                            shouldReplace={isDesktop && isSearchPage}
                                            href={getTypeSearchUrl(SearchContentTypes.Products)}
                                            onClick={onShowAllClick}
                                            text={`${formatString(
                                                translate('search.filterAndFacets.expectedResults'),
                                                resultCount[SearchContentTypes.Products] || 0
                                            )} `}
                                        />
                                    </SuggestionGridShowAllContainer>
                                )}

                                {!productResults?.length && (
                                    <Text>{translate('search.searchResultPage.noProductsToShow')}</Text>
                                )}
                            </StyledList>
                            {!!productResults?.length &&
                                (resultCount?.[SearchContentTypes.Products] || 0) > (productResults?.length || 0) && (
                                    <LoadMore hideOnDesktop>
                                        <Button
                                            loading={isLoadingMore}
                                            onClick={() => {
                                                onLoadMore?.(SearchContentTypes.Products);
                                                onAddHistoryItem(searchTerm);
                                            }}
                                        >
                                            {formatString(
                                                translate('search.searchResultPage.showMore'),
                                                resultCount?.[SearchContentTypes.Products] || 0
                                            )}
                                        </Button>
                                    </LoadMore>
                                )}
                            {!!serieResults?.series?.length && !hasActiveFacets && (
                                <SeriesContainer ref={seriesRef}>
                                    <StyledSuggestionSection>
                                        {isDesktop ? (
                                            <Flex alignItems="center" justifyContent="space-between">
                                                <StyledSuggetionTitle size="16px" fontWeight="Medium" as="p">
                                                    {translate('search.headerSearch.sectionHeadlines.series')}
                                                </StyledSuggetionTitle>
                                                <StyledShowAllLink
                                                    replace={isDesktop && isSearchPage}
                                                    themedLink
                                                    href={getTypeSearchUrl(SearchContentTypes.Series)}
                                                    onClick={onShowAllClick}
                                                >
                                                    {`${formatString(
                                                        translate('search.filterAndFacets.expectedResults'),
                                                        serieResults.series.length || 0
                                                    )} `}
                                                </StyledShowAllLink>
                                            </Flex>
                                        ) : (
                                            <Flex alignItems="center" justifyContent="space-between">
                                                <StyledSuggetionTitle size="16px" fontWeight="Medium" as="p">
                                                    {translate('search.headerSearch.sectionHeadlines.series')}
                                                </StyledSuggetionTitle>
                                            </Flex>
                                        )}
                                        <StyledList grid>
                                            {serieResults.series.map((serie, i) => (
                                                <SuggestionSerie
                                                    key={serie.serieEntityId}
                                                    serie={serie}
                                                    index={i}
                                                    focusIndex={focusIndex}
                                                    sectionIndex={sectionIndex}
                                                    setIndexes={setIndexes}
                                                    onClick={handleScrollRestoration}
                                                />
                                            ))}
                                        </StyledList>
                                        {!!serieResults?.series?.length && serieResults?.hasNextPage && (
                                            <LoadMore hideOnDesktop>
                                                <Button
                                                    loading={isLoadingMore}
                                                    onClick={() => {
                                                        onLoadMore?.(SearchContentTypes.Series, true);
                                                        onAddHistoryItem(searchTerm);
                                                    }}
                                                >
                                                    {formatString(
                                                        translate('search.searchResultPage.showMore'),
                                                        resultCount?.[SearchContentTypes.Series] || 0
                                                    )}
                                                </Button>
                                            </LoadMore>
                                        )}
                                    </StyledSuggestionSection>
                                </SeriesContainer>
                            )}
                        </StyledSuggestionSection>
                    )}
                </>

                {selectedTab === 'categories' && (
                    <StyledSuggestionSection ref={categoriesRef}>
                        <HeaderContainer alignItems="center" justifyContent="space-between">
                            {isDesktop ? (
                                <>
                                    <StyledSuggetionTitle size="16px" fontWeight="Medium" as="p">
                                        {translate('search.headerSearch.sectionHeadlines.categories')}
                                    </StyledSuggetionTitle>
                                    {!!resultCount?.[SearchContentTypes.Categories] && (
                                        <ShowAllLink
                                            shouldReplace={isDesktop && isSearchPage}
                                            href={getTypeSearchUrl(SearchContentTypes.Categories)}
                                            onClick={onShowAllClick}
                                            text={`${formatString(
                                                translate('search.filterAndFacets.expectedResults'),
                                                resultCount[SearchContentTypes.Categories] || 0
                                            )} `}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <HeaderSummary alignItems="center" justifyContent="center" gap="text" wrap="wrap">
                                        <Text>{translate('search.headerSearch.search')}</Text>
                                        <Text fontWeight="Bold">{`"${decodeURIComponent(searchTerm || '')}"`}</Text>
                                        <Text>{translate('search.headerSearch.match')}</Text>
                                        <Text fontWeight="Bold">{resultCount?.[SearchContentTypes.Categories]}</Text>
                                        <ScrollButton variant="link" onClick={scrollToCategories}>
                                            <Text variant="bodyLarge">{translate('search.tabs.categories')}</Text>
                                        </ScrollButton>
                                    </HeaderSummary>
                                </>
                            )}
                        </HeaderContainer>

                        <StyledList>
                            {categoryResults?.map(({ title, originalUrl, trackingCode }, i) => (
                                <StyledListElement
                                    onFocus={() => setIndexes?.(i, 2)}
                                    onMouseEnter={() => setIndexes?.(i, 2)}
                                    key={(originalUrl || '') + i}
                                    state={sectionIndex === 2 && focusIndex === i ? 'active' : ''}
                                >
                                    <StyledSuggestionLink href={originalUrl} onClick={() => handleClick(trackingCode)}>
                                        <SvgIcon size={20} svg="arrowRight" />
                                        <StyledSuggetionTexts>
                                            <SuggestionHeadline h6>{title}</SuggestionHeadline>
                                        </StyledSuggetionTexts>
                                    </StyledSuggestionLink>
                                </StyledListElement>
                            ))}

                            {!categoryResults?.length && (
                                <Text>{formatString(translate('search.searchResultPage.categories'), 0)}</Text>
                            )}
                        </StyledList>
                        {!!categoryResults?.length &&
                            (resultCount?.[SearchContentTypes.Categories] || 0) > (categoryResults?.length || 0) && (
                                <LoadMore hideOnDesktop>
                                    <Button
                                        loading={isLoadingMore}
                                        onClick={() => {
                                            onLoadMore?.(SearchContentTypes.Categories);
                                            onAddHistoryItem(searchTerm);
                                        }}
                                    >
                                        {formatString(
                                            translate('search.searchResultPage.showMore'),
                                            resultCount?.[SearchContentTypes.Categories] || 0
                                        )}
                                    </Button>
                                </LoadMore>
                            )}
                    </StyledSuggestionSection>
                )}
                {selectedTab === 'articles' && (
                    <StyledSuggestionSection ref={articlesRef}>
                        <HeaderContainer alignItems="center" justifyContent="space-between">
                            {isDesktop ? (
                                <>
                                    <StyledSuggetionTitle size="16px" fontWeight="Medium" as="p">
                                        {translate('search.headerSearch.sectionHeadlines.articles')}
                                    </StyledSuggetionTitle>
                                    {!!resultCount?.[SearchContentTypes.BlogPost] && (
                                        <ShowAllLink
                                            shouldReplace={isDesktop && isSearchPage}
                                            href={getTypeSearchUrl(SearchContentTypes.BlogPost)}
                                            onClick={onShowAllClick}
                                            text={`${formatString(
                                                translate('search.filterAndFacets.expectedResults'),
                                                resultCount[SearchContentTypes.BlogPost] || 0
                                            )} `}
                                        />
                                    )}
                                </>
                            ) : (
                                <>
                                    <HeaderSummary alignItems="center" justifyContent="center" gap="text" wrap="wrap">
                                        <Text>{translate('search.headerSearch.search')}</Text>
                                        <Text fontWeight="Bold">{`"${decodeURIComponent(searchTerm || '')}"`}</Text>
                                        <Text>{translate('search.headerSearch.match')}</Text>
                                        <Text fontWeight="Bold">{resultCount?.[SearchContentTypes.BlogPost]}</Text>

                                        <ScrollButton variant="link" onClick={scrollToArticles}>
                                            <Text variant="bodyLarge">{translate('search.tabs.blogPost')}</Text>
                                        </ScrollButton>
                                    </HeaderSummary>
                                </>
                            )}
                        </HeaderContainer>

                        <ul>
                            {articleResults?.map(({ title, originalUrl, trackingCode }, i) => (
                                <StyledListElement
                                    onFocus={() => setIndexes?.(i, 3)}
                                    onMouseEnter={() => setIndexes?.(i, 3)}
                                    key={(originalUrl || '') + i}
                                    state={sectionIndex === 3 && focusIndex === i ? 'active' : ''}
                                >
                                    <StyledSuggestionLink href={originalUrl} onClick={() => handleClick(trackingCode)}>
                                        <SvgIcon size={20} svg="arrowRight" />
                                        <Text>{title}</Text>
                                    </StyledSuggestionLink>
                                </StyledListElement>
                            ))}
                            {!!articleResults?.length &&
                                (resultCount?.[SearchContentTypes.BlogPost] || 0) > (articleResults?.length || 0) && (
                                    <LoadMore hideOnDesktop>
                                        <Button
                                            loading={isLoadingMore}
                                            onClick={() => {
                                                onLoadMore?.(SearchContentTypes.BlogPost);
                                                onAddHistoryItem(searchTerm);
                                            }}
                                        >
                                            {formatString(
                                                translate('search.searchResultPage.showMore'),
                                                resultCount?.[SearchContentTypes.BlogPost] || 0
                                            )}
                                        </Button>
                                    </LoadMore>
                                )}
                            {!articleResults?.length && (
                                <Text>{formatString(translate('search.searchResultPage.articles'), 0)}</Text>
                            )}
                        </ul>
                    </StyledSuggestionSection>
                )}
            </SearchSuggestionsMainContent>
        </SearchSuggestionsWrapper>
    );
});

const SuggestionHeadline = styled(Text)(({ theme }) => ({
    margin: 0,
    marginBottom: theme.space[1],
    ...theme.mixins.useTextStyle('bodySmall'),
    fontWeight: theme.fontWeights.bold,
    ...ellipsis,
}));

const HeaderContainer = styled(Flex)(({ theme }) => ({
    [mq('lg')]: {
        paddingTop: theme.space[3],
    },
}));

const HeaderSummary = styled(Flex)(({ theme }) => ({
    margin: 0,
    paddingTop: theme.space[5],
    paddingBottom: theme.space[5],
    width: '100%',
}));

const StyledShowAllLink = styled(Link)(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.fontWeights.medium,
}));

const LoadMore = styled.div<{ hideOnDesktop?: boolean }>(({ hideOnDesktop, theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.space[4],
    ...(hideOnDesktop && {
        [mq('lg')]: {
            display: 'none',
        },
    }),
}));

const SeriesContainer = styled.div(({ theme }) => ({
    borderTop: theme.general.border,
    marginTop: theme.space[9],
    [mq('frame')]: {
        display: 'none',
    },
}));

const ScrollButton = styled(Button)({});
const SeriesCount = styled(Text)({});
const ResetFacets = styled(Button)({});

const SearchWordItem = styled(StyledListElement)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    // Testing spacing
    padding: `${theme.space[2]} 0`,
}));
