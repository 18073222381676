import React, { FC, memo } from 'react';
import { StyledListElement } from '../shared/styles';
import { ISearchSerieResultDetails } from '$models';
import { SuggestionResultCard } from '../suggestion-result-card';
import { WEB_URL } from '$lib/helpers';

type Props = {
    serie: ISearchSerieResultDetails;
    index: number;
    sectionIndex?: number;
    focusIndex?: number;
    setIndexes?: (sectionIndex: number, focusIndex: number) => void;
    onClick?: () => void;
};

export const SuggestionSerie: FC<Props> = memo((props: Props) => {
    const { serie, index, sectionIndex, focusIndex, setIndexes, onClick } = props;

    return (
        <StyledListElement
            key={serie?.serieEntityId}
            as="li"
            state={sectionIndex === 1 && focusIndex === index ? 'active' : ''}
            onFocus={() => setIndexes?.(index, 1)}
            onMouseEnter={() => setIndexes?.(index, 1)}
        >
            <SuggestionResultCard
                imageSrc={serie.serieImage}
                fallbackImageSrc={!serie?.serieImage ? `${WEB_URL}/assets/bog_billede_mangler.jpg` : undefined}
                title={serie.serieTitle}
                url={serie.serieLink}
                onClickSideEffect={onClick}
            />
        </StyledListElement>
    );
});
