import React, { FC } from 'react';
import styled from '@emotion/styled';
import { cover as stretch, shimmer as shim } from '$lib/style-helpers';

type Props = {
    height?: number;
    width?: number;
    shimmer?: boolean;
    cover?: boolean;
};

export const Skeleton: FC<Props> = (props: Props) => <Block {...props} />;

const Block = styled.div<Props>(({ theme, width, height, cover, shimmer }) => ({
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: theme.general.borderRadius,
    ...(cover ? { ...stretch } : {}),
    ...(shimmer ? { ...shim } : { backgroundColor: theme.colors.primaryGreyLight }),
}));
