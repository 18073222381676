import { useEffect, useState } from 'react';
import { LocalStorageKeys } from '$constants';
import { clearLocalStorage, getLocalStorage, setLocalStorage } from '$services/local-storage';

export const useSearchHistory = () => {
    const [history, setHistory] = useState<string[]>([]);

    useEffect(() => {
        const getHistory = async () => {
            const data = await getLocalStorage<string[]>(LocalStorageKeys.SearchHistory);
            if (data) {
                setHistory(data);
            }
        };
        getHistory();
    }, []);

    const onAddHistoryItem = (query?: string) => {
        if (!query) {
            return;
        }
        if (history.includes(query)) {
            return;
        }
        const list = [...history];
        list.unshift(query);
        const newList = list.slice(0, 3);
        setLocalStorage(LocalStorageKeys.SearchHistory, newList);
        setHistory(newList);
    };

    const onClearHistory = () => {
        clearLocalStorage(LocalStorageKeys.SearchHistory);
        setHistory([]);
    };

    return { history, onClearHistory, onAddHistoryItem };
};
