import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDebounce } from 'react-use';
import { isDefined } from '$lib/helpers/is-defined.helper';
import {
    IHelloRetailFilterSelections,
    IHelloRetailSelectedFacet,
    IHelloRetailSortingOption,
    SuggestionOptionProps,
} from '~/modules/search/interfaces/suggestion';
import { SearchContentTypes } from '../interfaces/search';
import { useSuggestionsInitialContent } from '../hooks/use-suggestions-initial-content.hook';
import { useLocalSuggestion } from './use-local-suggestion';
import { useHelloRetailSuggestion } from './use-hello-retail-suggestion';
import { getFilterParamsFromHelloRetailResult } from '~/modules/filter/helpers';

export const useCombinedSearchSuggestion = (
    term: string,
    {
        debounceMs = 500,
        inputLength = 0,
        overlayOpen,
        isCurrentSearch,
        enableQueryHandling,
        isDesktop,
        currentTab,
        initialFacets,
    }: SuggestionOptionProps & {
        searchType?: SearchContentTypes;
        overlayOpen?: boolean;
        isCurrentSearch?: boolean;
        enableQueryHandling?: boolean;
        isDesktop?: boolean;
        currentTab?: string;
        initialFacets?: { sorting: string; selectedFacets: IHelloRetailSelectedFacet[]; size?: number };
    }
) => {
    const [debouncedTerm, setDebouncedTerm] = useState('');
    const { data: initialContent, title: initialContentTitle } = useSuggestionsInitialContent({ overlayOpen });
    const [oldDataKeyHelloRetail, setOldDataKeyHelloRetail] = useState<string>('');
    const [oldDataKeyLocal, setOldDataKeyLocal] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { asPath } = useRouter();

    const {
        setFacets,
        productResults,
        productTotalCount,
        categoryResults,
        categoryTotalCount,
        blogPostResults,
        blogPostTotalCount,
        storeResults,
        storeTotalCount,
        productZeroResults,
        productZeroResultsTitle,
        loading,
        loadingPage,
        loadNextPage,
        productFacets,
        productSorting: sortingOptions,
        searchWordResults,
        dataKey: helloRetailDataKey,
        onResetSize: onResetSizeHelloRetail,
        size,
    } = useHelloRetailSuggestion(
        debouncedTerm,
        isDesktop ? 4 : undefined,
        initialFacets?.sorting,
        initialFacets?.selectedFacets,
        initialFacets?.size,
        overlayOpen
    );

    const {
        serieResults,
        seriesTotalCount,
        loadNextPage: loadNextPageLocal,
        loading: loadingLocal,
        loadingPage: loadingLocalPage,
        dataKey: localDataKey,
        onResetSize: onResetSizeLocal,
    } = useLocalSuggestion(debouncedTerm, overlayOpen);

    const onSetFacets = (newFacets: IHelloRetailFilterSelections) => {
        setIsLoading(true);
        setFacets(newFacets);
    };

    const onLoadMore = (type: SearchContentTypes, isLocal?: boolean) => {
        if (isLocal) {
            loadNextPageLocal();
        } else {
            loadNextPage(type);
        }
    };

    const onResetSize = () => {
        onResetSizeHelloRetail();
        onResetSizeLocal();
    };

    // Set loading to false on every result update.
    useEffect(() => {
        if (oldDataKeyHelloRetail !== helloRetailDataKey) {
            setOldDataKeyHelloRetail(helloRetailDataKey);
            setIsLoading(false);
        }
    }, [helloRetailDataKey]);
    // Set loading to false on every result update.
    useEffect(() => {
        if (oldDataKeyLocal !== localDataKey) {
            setOldDataKeyLocal(localDataKey);
            setIsLoading(false);
        }
    }, [localDataKey]);

    useDebounce(
        () => {
            if (isCurrentSearch) {
                return;
            }
            const searchTerm = isDefined(inputLength) && term.length >= inputLength ? term : '';

            setDebouncedTerm(searchTerm);
        },
        debounceMs,
        [term]
    );

    const clearSuggestionQuery = () => {
        const newUrl = asPath.split('?')[0];
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    };

    if (enableQueryHandling) {
        if (overlayOpen) {
            let newUrl = asPath.split('?')[0];

            newUrl += `?openSearchSuggestion=true`;
            if (debouncedTerm.length > 0) {
                newUrl += `&suggestionSearch=${debouncedTerm}`;
            }
            if (currentTab && currentTab !== 'products') {
                newUrl += `&suggestionTab=${currentTab}`;
            }
            if (size > 1) {
                newUrl += `&suggestionPageSize=${size}`;
            }
            const productFilterParams = getFilterParamsFromHelloRetailResult(productFacets);
            Object.entries(productFilterParams).forEach(([key, value]) => {
                value.forEach((v) => {
                    newUrl += `&${encodeURIComponent(key)}=${encodeURIComponent(v)}`;
                });
            });

            const selectedSorting = sortingOptions?.find((option: IHelloRetailSortingOption) => option?.selected);
            if (selectedSorting) {
                newUrl += `&suggestionSorting=${encodeURIComponent(selectedSorting?.query)}`;
            }
            window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
        }
    }

    return {
        data: productResults,
        storeResults,
        articleResults: blogPostResults,
        serieResults,
        searchWordResults,
        categoryResults,
        error: null,
        isLoading: loading || loadingLocal || isLoading || term !== debouncedTerm,
        initialContent,
        initialContentTitle,
        resultCount: {
            products: productTotalCount || 0,
            series: seriesTotalCount || 0,
            blogPost: blogPostTotalCount || 0,
            sitePages: storeTotalCount || 0,
            categories: categoryTotalCount || 0,
        },
        zeroResultData: productZeroResults,
        zeroResultTitle: productZeroResultsTitle,
        onSetFacets,
        onLoadMore,
        onResetSize,
        clearSuggestionQuery,
        productFacets,
        sortingOptions,
        loadingNextPage: loadingPage || loadingLocalPage,
    };
};
