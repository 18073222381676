import React, { FC, memo } from 'react';
import {
    SearchSuggestionsMainContent,
    SearchSuggestionsSideContent,
    SearchSuggestionsWrapper,
    StyledElementPlaceholder,
    StyledElementPlaceholderHeader,
    StyledListElement,
} from '../shared/styles';

type Variant = 'plain' | 'normal';

type Props = {
    variant?: Variant;
    pageSize: number;
};

export const SuggestionLoader: FC<Props> = memo((props: Props) => {
    const { variant = 'normal', pageSize } = props;

    return (
        <SearchSuggestionsWrapper variant={variant}>
            <SearchSuggestionsMainContent>
                <ul>
                    <StyledListElement>
                        <StyledElementPlaceholderHeader shimmer />
                    </StyledListElement>

                    {[...Array(pageSize)].map((_, i) => (
                        <StyledListElement key={i}>
                            <StyledElementPlaceholder shimmer />
                        </StyledListElement>
                    ))}
                </ul>
            </SearchSuggestionsMainContent>
            <SearchSuggestionsSideContent>
                <ul>
                    {/* <StyledListElement>
                        <StyledElementPlaceholderHeader shimmer />
                    </StyledListElement> */}

                    {[...Array(pageSize)].map((_, i) => (
                        <StyledListElement key={i}>
                            <StyledElementPlaceholder shimmer />
                        </StyledListElement>
                    ))}
                </ul>
            </SearchSuggestionsSideContent>
        </SearchSuggestionsWrapper>
    );
});
