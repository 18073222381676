import { useEffect, useState } from 'react';
import { useSWRInfinite } from 'swr';
import fetcher from '$lib/fetcher';
import { IFullSearchResult, ISearchSerieResult } from '$models';
import { SearchContentTypes } from '~/modules/search';
import { key } from '~/utils';
import { getUrlKeyForSearch } from '~/modules/filter/helpers';

export const useLocalSuggestion = (search: string, overlayOpen?: boolean) => {
    const [serieResults, setSerieResults] = useState<ISearchSerieResult>();
    const [seriesTotalCount, setSeriesTotalCount] = useState<number>();
    const baseMode = SearchContentTypes.Series;

    useEffect(() => {
        if (!search) {
            setSerieResults(undefined);
            setSeriesTotalCount(undefined);
        }
    }, [search]);

    const { data, error, size, setSize } = useSWRInfinite<IFullSearchResult>(
        (index) =>
            getUrlKeyForSearch(
                index,
                overlayOpen ? search : '',
                baseMode,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined
            ),
        fetcher,
        {
            // revalidateOnMount: false,
            // revalidateOnFocus: false,
            // initialSize: 1,
        }
    );

    const loadNextPage = () => {
        setSize((prev) => prev + 1);
    };

    const onResetSize = () => {
        setSize(1);
    };

    const loading = !data && !error;

    const lastEntry = data?.[data.length - 1];

    useEffect(() => {
        if (!data || !Array.isArray(data)) {
            return;
        }
        const lastSeriesAndAuthorsEntry = data?.[data.length - 1];
        const series = data?.length
            ? data
                  ?.map((d) => d.series)
                  ?.reduce((acc, cur) => ({
                      ...acc,
                      series: [...(acc?.series || []), ...(cur?.series || [])],
                  }))
            : undefined;
        if (series && lastSeriesAndAuthorsEntry) {
            series.hasNextPage = lastSeriesAndAuthorsEntry?.series?.hasNextPage;
        }
        setSerieResults(series);
        setSeriesTotalCount(series?.totalDocumentsFound || 0);
    }, [data]);

    const dataKey = lastEntry ? key(lastEntry) : 'no-data';

    return {
        loading,
        loadingPage: size > 0 && !data?.[size - 1],
        loadNextPage,
        searchTerm: data?.[0]?.searchTerm || search,
        serieResults,
        seriesTotalCount,
        dataKey,
        onResetSize,
    };
};
