import React from 'react';
import styled from '@emotion/styled';
import { darken } from 'color2k';
import { Link } from '$components/elements/link';
import { Responsive } from '$components/elements/responsive';

type Props = {
    href?: string;
    text?: string;
    shouldReplace?: boolean;
    onClick?: () => void;
};

export const ShowAllLink = ({ href, text, shouldReplace, onClick }: Props) => {
    return (
        <>
            <Responsive max="lg">
                <StyledShowAllLink themedLink href={href} onClick={onClick}>
                    {text}
                </StyledShowAllLink>
            </Responsive>
            <Responsive min="lg">
                <ButtonLink applyTextStyling={false} href={href} replace={shouldReplace} onClick={onClick}>
                    {text}
                </ButtonLink>
            </Responsive>
        </>
    );
};

const StyledShowAllLink = styled(Link)(({ theme }) => ({
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.fontWeights.medium,
}));

const ButtonLink = styled(Link)(({ theme }) => ({
    all: 'unset',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRadius: '100px',
    minWidth: '100px',
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.buttonPrimary,
    color: theme.colors.white,
    fontSize: theme.fontSizes.sm,
    height: '50px',
    padding: `0 ${theme.space[4]}`,
    minHeight: theme.space[4],

    '&:hover': {
        backgroundColor: darken(theme.colors.buttonPrimary, 0.2),
    },
}));
