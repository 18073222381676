import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { useTranslation } from '$hooks';
import { useBasket } from '~/store';
import { IBasketLine, IPriceCustomerSpecificPrice } from '$models';
import { Flex } from '$components/layouts';
import { formatPrice, formatString, isSpecialPrice, mq } from '$lib/helpers';
import { Text } from '$components/elements/text';
import { Link } from '$components/elements/link';
import { Image } from '$components/elements/image';
import { SvgIcon } from '$components/elements/svg-icon';
import { Button } from '$components/elements/button';

type Props = { line: IBasketLine };

export const MiniBasketItem: FC<Props> = ({ line }) => {
    const { translate } = useTranslation();
    const { removeBasketLine, updateGift } = useBasket();
    const theme = useTheme();

    const onRemoveLine = (line: IBasketLine) => {
        if (line.isGiftShopItem) {
            updateGift({
                isInternalItem: false,
                description: line.product?.description,
                quantity: 0,
                itemNumber: line.product?.itemNumber,
            });
        } else {
            removeBasketLine(line?.product?.itemNumber);
        }
    };

    const hasSpecialPrice = (prices: IPriceCustomerSpecificPrice[]) => {
        return prices.some((price) => isSpecialPrice(price.accountRelation));
    };

    return (
        <MiniBasketItemContainer>
            <Link href={line.productUrl}>
                <LineImageWrapper>
                    {!!line.primaryImageUrl && <Image height={60} src={line.primaryImageUrl} />}
                </LineImageWrapper>
            </Link>
            <LineContentWrapper column grow>
                <Flex justifyContent="space-between" alignItems="center" spacingBottom="extrasmall">
                    <Link href={line.productUrl}>
                        <Text
                            h5
                            size={theme.fontSizes.default}
                            font="regular"
                            fontWeight="Bold"
                            lineHeight={theme.lineHeights.single}
                            color={theme.colors.black}
                        >
                            {line?.product?.itemName}
                        </Text>
                    </Link>
                    <RemoveLineCTA variant="custom" onClick={() => onRemoveLine(line)}>
                        <SvgIcon color={theme.colors.red} svg="trash" />
                    </RemoveLineCTA>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                    <Text color={theme.colors.primaryGrey}>
                        {formatString(translate('header.miniBasket.lineQuantityText'), line.quantity || 0)}
                    </Text>
                    <MiniBasketPrices gap="extrasmall">
                        {!!line.prices?.length && hasSpecialPrice(line.prices) ? (
                            <Text color={theme.colors.primaryTheme}>{formatPrice(line.lineTotalPrice)}</Text>
                        ) : (
                            <>
                                {!!line.lineDiscount && !!line.lineTotalPrice && (
                                    <Discount color={theme.colors.primaryGrey}>
                                        {formatPrice(line.lineTotalPrice + line.lineDiscount)}
                                    </Discount>
                                )}
                                <Text>{formatPrice(line.lineTotalPrice)}</Text>
                            </>
                        )}
                    </MiniBasketPrices>
                </Flex>
            </LineContentWrapper>
        </MiniBasketItemContainer>
    );
};

const LineImageWrapper = styled.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '40px',
    height: '40px',

    [mq('md')]: {
        width: '80px',
        height: '60px',
    },
});

const LineContentWrapper = styled(Flex)(({ theme }) => ({
    marginLeft: theme.space[3],

    [mq('md')]: {
        marginLeft: theme.space[5],
    },
}));

const MiniBasketItemContainer = styled(Flex)(({ theme }) => ({
    paddingTop: theme.space[2],
    paddingBottom: theme.space[4],
    marginBottom: theme.space[2],
    borderBottom: theme.general.border,
}));

const Discount = styled(Text)({
    textDecoration: 'line-through',
    order: 2,

    [mq('md')]: {
        order: 'initial',
    },
});

const MiniBasketPrices = styled(Flex)({
    flexDirection: 'column',

    [mq('md')]: {
        flexDirection: 'row',
    },
});

const RemoveLineCTA = styled(Button)(({ theme: { space } }) => ({
    padding: `0 ${space[1]}`,
    paddingBottom: space[1],
}));
