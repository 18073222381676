import React, { FC, PropsWithChildren, ReactNode, useRef } from 'react';
import styled from '@emotion/styled';
import { ifProp, switchProp } from 'styled-tools';
import { useClickAway, useLockBodyScroll } from 'react-use';
import { Interpolation, keyframes, Theme } from '@emotion/react';

type HorizontalPosition = 'left' | 'center' | 'right';

type PopoverProps = {
    open: boolean;
    anchor: ReactNode;
    onClickOutside: () => void;
    hPosition: HorizontalPosition;
    contentCss?: Interpolation<Theme>;
    enableScrollLock?: boolean;
};

export const Popover: FC<PropsWithChildren<PopoverProps>> = ({
    open,
    children,
    anchor,
    contentCss,
    onClickOutside,
    hPosition,
    enableScrollLock = false,
}) => {
    const popoverRef = useRef(null);

    useLockBodyScroll(enableScrollLock && open);

    useClickAway(popoverRef, onClickOutside);

    return (
        <PopoverWrapper ref={popoverRef} open={open} hideBackdrop={enableScrollLock}>
            {anchor}
            {open ? (
                <PopoverContent hPosition={hPosition} css={contentCss}>
                    {children}
                </PopoverContent>
            ) : (
                <></>
            )}
            {open && enableScrollLock ? <Backdrop onClick={onClickOutside} open={open} /> : <></>}
        </PopoverWrapper>
    );
};

const PopoverWrapper = styled.div<{ open?: boolean; hideBackdrop?: boolean }>(
    ({ hideBackdrop }) => ({
        position: 'relative',

        // Backdrop for search modal
        ...(!hideBackdrop && {
            '&:before': {
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                width: '100vw',
                height: '100vh',
                transitionDuration: '.4s',
                pointerEvents: 'none',
                zIndex: 1,
            },
        }),
    }),
    ifProp('open', ({ hideBackdrop }) => ({
        ...(!hideBackdrop && {
            '&:before': {
                content: "''",
                background: 'rgba(0,0,0,0.12)',
            },
        }),
    }))
);

const Backdrop = styled.div<{ open?: boolean }>(() => ({
    content: "''",
    background: 'rgba(0,0,0,0.12)',
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    transitionDuration: '.4s',
    zIndex: 1,
}));

const slideUpAndFadeCenter = keyframes({
    '0%': { opacity: 0, transform: 'translate(-50%, 2px)' },
    '100%': { opacity: 1, transform: 'translate(-50%, 0)' },
});

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const PopoverContent = styled.div<{ hPosition: HorizontalPosition }>(
    ({ theme }) => ({
        zIndex: theme.zIndices.popover,
        animation: `${slideUpAndFade} 0.2s ease-out`,
        position: 'absolute',
        top: 'calc(100% + 20px)',
    }),
    switchProp('hPosition', {
        left: {
            left: 0,
        },
        center: {
            animation: `${slideUpAndFadeCenter} 0.2s ease-out`,
            left: '50%',
            transform: 'translateX(-50%)',
        },
        right: {
            right: 0,
        },
    })
);
