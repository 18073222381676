import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { mq } from '$lib/helpers';
import { Skeleton } from '$components/elements/skeleton';
import { Link } from '$components/elements/link';
import { Flex } from '$components/layouts';
import { Text } from '$components/elements/text';

type Variant = 'plain' | 'normal';

export const SearchSuggestionsWrapper = styled.div<{ variant: Variant }>(
    ({ theme: { colors } }) => ({
        backgroundColor: colors.white,
        display: 'grid',
        gridTemplateColumns: '100%',
        gridTemplateRows: 'auto',
        paddingBottom: '40px',
        // padding: `${space[2]} 0 ${space[2]} ${space[6]}`,
        // [mq('sm')]: {
        //     padding: `${space[2]} ${space[6]}`,
        // },
        [mq('frame')]: {
            overflow: 'hidden',
            maxHeight: '85vh',
            // padding: `${space[5]} ${space[6]}`,
        },
        [mq('lg')]: {
            paddingBottom: '10px',
            // gridTemplateColumns: '60% 40%',
        },
    }),
    switchProp('variant', {
        normal: () => ({
            borderRadius: '24px',
        }),
    })
);

export const SearchSuggestionsMainContent = styled.div(({ theme: { space } }) => ({
    overflowY: 'auto',
    maxHeight: '85vh',

    padding: `0 ${space[6]}`,
    margin: `${space[2]} 0`,
    [mq('frame')]: {
        padding: `0 ${space[6]}`,
        margin: `${space[5]} 0`,
        maxHeight: '75vh',
    },
}));
// export const SearchSuggestionsMainContent = styled.div();
export const SearchSuggestionsSideContent = styled.div();
// export const SearchSuggestionsSideContent = styled.div(({ theme: { colors, space } }) => ({
//     [mq('lg')]: {
//         borderLeft: `1px solid ${colors.primaryGreyLight}`,
//         padding: `0 ${space[6]}`,
//         marginLeft: space[6],
//     },
// }));

export const StyledList = styled.ul<{ withBorder?: boolean; withBorderMobile?: boolean; grid?: boolean }>(
    ({ withBorder, withBorderMobile, grid, theme }) => ({
        ...(withBorder && {
            borderBottom: `1px solid ${theme.colors.primaryGreyLight}`,
            padding: `${theme.space[2]} 0`,
            [mq('lg')]: {
                padding: `0 0 ${theme.space[5]}`,
            },
        }),
        ...(withBorderMobile && {
            [mq(0, 'lg')]: {
                borderBottom: `1px solid ${theme.colors.primaryGreyLight}`,
                paddingBottom: theme.space[5],
            },
        }),
        ...(grid && {
            width: '100%',
            display: 'grid',
            gridTemplateColumns: '50% 50%',
            gridTemplateRows: 'auto',
            gap: theme.space[3],
            [mq('lg')]: {
                gridTemplateColumns: 'calc(25% - 9px) calc(25% - 9px) calc(25% - 9px) calc(25% - 9px)',
            },
        }),
    })
);
export const StyledListElement = styled.li<{ state?: 'active' | '' }>(
    ({ theme }) => ({
        fontWeight: theme.fontWeights.bold,
        fontSize: theme.fontSizes.sm,
        padding: `${theme.space[3]} 0`,
        position: 'relative',
    })
    // switchProp('state', {
    //     active: () => ({
    //         background: '#F5F5F5',
    //     }),
    // })
);

export const StyledElementPlaceholder = styled(Skeleton)(() => ({
    width: '100%',
    height: '60px',
}));

export const StyledElementPlaceholderHeader = styled(Skeleton)(() => ({
    width: '60px',
    height: '15px',
}));

export const StyledLink = styled(Link)(({ theme: { space } }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: space[5],
}));

export const StyledImageContainer = styled.div({
    marginLeft: 'auto',
});

export const StyledSuggetionTexts = styled.span(({ theme }) => ({
    flex: 1,
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.space[3],
}));

export const StyledSuggetionRightFlexSection = styled(Flex)(() => ({
    width: '40%',
    maxWidth: '220px',
}));

export const StyledSuggestionSection = styled.div<{ hideOnDesktop?: boolean }>(({ hideOnDesktop, theme }) => ({
    marginBottom: `${theme.space[12]}`,
    [mq('frame')]: {
        marginBottom: `${theme.space[1]}`,
    },
    [mq('lg')]: {
        marginBottom: `${theme.space[1]}`,
        ...(hideOnDesktop && {
            display: 'none',
        }),
    },
}));

export const StyledSuggetionTitle = styled(Text)(({ theme }) => ({
    margin: 0,
    paddingTop: theme.space[5],
    paddingBottom: theme.space[5],
}));

export const StyledSuggestionLink = styled(Link)<{ contentWidth?: boolean }>(({ contentWidth, theme: { space } }) => ({
    display: 'flex',
    width: contentWidth ? 'initial' : '100%',
    alignItems: 'center',
    gap: space[5],
}));

export const StyledProductGrid = styled.ul(({ theme }) => ({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridTemplateRows: 'auto',
    gap: theme.space[3],
    [mq('lg')]: {
        gridTemplateColumns: 'calc(25% - 9px) calc(25% - 9px) calc(25% - 9px) calc(25% - 9px)',
    },
}));

export const SuggestionGridShowAllContainer = styled.div(({ theme: { space } }) => ({
    display: 'none',
    [mq('lg')]: {
        display: 'flex',
        justifyContent: 'flex-end',
        gridColumnStart: 1,
        gridColumnEnd: 5,
        marginBottom: space[2],
    },
}));
