import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { ellipsis } from '$lib/style-helpers';
import { Text } from '$components/elements/text';

type Props = {
    searchWord?: string;
    title?: string;
};

export const SuggestionSearchWord: FC<Props> = ({ searchWord, title }: Props) => {
    const highlightedTitle = useMemo(() => {
        if (!searchWord || !title) {
            return title;
        }
        try {
            return title
                ?.split(new RegExp(`(${searchWord})`, 'gi'))
                .map((part, i) => (part.toLowerCase() === searchWord.toLowerCase() ? <b key={i}>{part}</b> : part));
        } catch (error) {
            return title;
        }
    }, [searchWord, title]);

    return <SuggestionHeader>{highlightedTitle}</SuggestionHeader>;
};

const SuggestionHeader = styled(Text)(({ theme }) => ({
    margin: 0,
    // marginBottom: theme.space[1],
    ...theme.mixins.useTextStyle('bodySmall'),
    ...ellipsis,
}));
