import { useEffect, useState } from 'react';
import { useSWRInfinite } from 'swr';
import fetcher from '$lib/fetcher';
import { isDefined } from '$lib/helpers';

import {
    IHelloRetailBlogPost,
    IHelloRetailCategory,
    IHelloRetailFilterSelections,
    IHelloRetailSearchResponse,
    IHelloRetailSearchWordSuggestion,
    IHelloRetailSelectedFacet,
    IHelloRetailSitePage,
    IMappedHelloRetailProduct,
} from '~/modules/search/interfaces/suggestion';
import { SearchContentTypes } from '~/modules/search';
import {
    createSearchUrlWithFilters,
    facetsFromInitialData,
    helloRetailProductFacetsAdapter,
    helloRetailProductSortingMapper,
    mapHelloRetailProducts,
} from '$lib/helpers/hello-retail.helper';
import { getSearchUrl } from '$services/search';
import { key } from '~/utils';
import { DEFAULT_SORTING_HELLO_RETAIL } from '~/modules/filter/helpers';

export const useHelloRetailSuggestion = (
    search: string,
    pageSize = 24,
    initialSorting?: string,
    initialFacets?: IHelloRetailSelectedFacet[],
    initialSize?: number,
    overlayOpen?: boolean
) => {
    const baseMode = SearchContentTypes.All;

    const [currentResults, setCurrentResults] = useState<any>();
    const [currentSearch, setCurrentSearch] = useState(search);

    useEffect(() => {
        if (currentSearch !== search && !!currentSearch) {
            setFacets([]);
            setSelectedSortOrderKey(DEFAULT_SORTING_HELLO_RETAIL);
        }
        setCurrentSearch(search);
        if (!search) {
            setCurrentResults(undefined);
        }
    }, [search]);

    useEffect(() => {
        if (initialSorting) {
            setSelectedSortOrderKey(initialSorting);
        }
        if (initialFacets) {
            setFacets(initialFacets);
        }
        if (initialSize) {
            setSize(initialSize);
        }
    }, [initialSorting, initialFacets, initialSize]);

    const [lastEntry, setLastEntry] = useState<IHelloRetailSearchResponse[]>();

    const [selectedSortOrderKey, setSelectedSortOrderKey] = useState<string | undefined>(
        initialSorting ? initialSorting : DEFAULT_SORTING_HELLO_RETAIL
    );

    const [facets, setFacets] = useState<IHelloRetailSelectedFacet[]>(initialFacets || []);

    const { data, error, size, setSize } = useSWRInfinite<IHelloRetailSearchResponse>(
        (index) =>
            getSearchUrl(
                createSearchUrlWithFilters({
                    index: index,
                    search: overlayOpen ? currentSearch : '',
                    facetParams: facets,
                    pageSize: pageSize,
                    sortOrderKey: selectedSortOrderKey,
                }),
                baseMode
            ),
        fetcher,
        {
            initialSize: initialSize || 1,
            // revalidateOnMount: false,
            // revalidateOnFocus: false,
        }
    );

    // Load next page can either set filter or simply fetch next page with current filter.
    // Logic needed to accommodate for adapting search result page.
    const loadNextPage = () => {
        setSize((prev) => prev + 1);
    };

    const onResetSize = () => {
        setSize(1);
    };

    const onSetFacets = (newFacets: IHelloRetailFilterSelections) => {
        if (newFacets.sorting) {
            if (newFacets.sorting.query !== DEFAULT_SORTING_HELLO_RETAIL) {
                setSelectedSortOrderKey(newFacets.sorting.query);
            } else {
                setSelectedSortOrderKey('');
            }
        } else {
            setSelectedSortOrderKey('');
        }
        setFacets(newFacets.selectedFacets || []);
        // reset size on facet update
        // setBaseState({ mode: SearchContentTypes.Products, size: 1 });
    };

    const loading = !data && !error;

    useEffect(() => {
        if (!data || !Array.isArray(data)) {
            return;
        }
        // Use last data entry for meta data.
        const lastEntry = data?.[data.length - 1];
        setLastEntry(data);

        if (!facets && lastEntry?.products?.filters) {
            setFacets(facetsFromInitialData(lastEntry?.products?.filters));
        }

        const productResults: IMappedHelloRetailProduct[] | undefined = data
            ?.map((d) => mapHelloRetailProducts(d?.products?.results))
            .reduce((a, c) => [...(a || []), ...(c || [])], [])
            .filter(isDefined);

        const productZeroResults: IMappedHelloRetailProduct[] | undefined = data
            ?.map((d) => mapHelloRetailProducts(d?.products?.zeroResultContent?.[0]?.products, true))
            .reduce((a, c) => [...(a || []), ...(c || [])], [])
            .filter(isDefined);
        const productZeroResultsTitle: string | undefined = data?.[0]?.products?.zeroResultContent?.[0]?.title;

        const productFacets = helloRetailProductFacetsAdapter(lastEntry?.products?.filters);

        const productSorting = helloRetailProductSortingMapper(lastEntry?.products?.sorting);

        const categoryResults: IHelloRetailCategory[] | undefined = data
            ?.map((d) => d.categories?.results || [])
            .reduce((a, c) => [...(a || []), ...(c || [])], [])
            .filter(isDefined);

        const blogPostResults: IHelloRetailBlogPost[] | undefined = data
            ?.map((d) => d.blogPosts?.results || [])
            .reduce((a, c) => [...(a || []), ...(c || [])], [])
            .filter(isDefined);

        const storeResults: IHelloRetailSitePage[] | undefined = data
            ?.map((d) => d.sitePages?.results || [])
            .reduce((a, c) => [...(a || []), ...(c || [])], [])
            .filter(isDefined);

        const searchWordResults: IHelloRetailSearchWordSuggestion[] | undefined = data
            ?.map((d) => d.brands?.results || [])
            .reduce((a, c) => [...(a || []), ...(c || [])], [])
            .filter(isDefined);

        setCurrentResults({
            productResults,
            productTotalCount: lastEntry?.products?.totalCount || 0,
            categoryResults,
            categoryTotalCount: lastEntry?.categories?.totalCount || 0,
            blogPostResults,
            blogPostTotalCount: lastEntry?.blogPosts?.totalCount || 0,
            storeResults,
            storeTotalCount: lastEntry?.sitePages?.totalCount || 0,
            searchWordResults,
            productFacets,
            productSorting,
            productZeroResults,
            productZeroResultsTitle,
        });
    }, [data]);

    const dataKey = lastEntry ? key(lastEntry) : 'no-data';

    return {
        loading,
        loadingPage: size > 0 && !data?.[size - 1],
        loadNextPage,
        searchTerm: search,
        setFacets: onSetFacets,
        selectedSortOrderKey: selectedSortOrderKey,
        dataKey,
        onResetSize,
        size,
        ...currentResults,
    };
};
