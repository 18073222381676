import { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { AnimatePresence, motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { Flex } from '$components/layouts';
import { B2CDeliveryMethod, PRODUCTION, basketPageUrl, checkoutPageUrl } from '$constants';
import { useTranslation } from '$hooks';
import { formatPrice, formatString, mq } from '$lib/helpers';
import { IIShippingType } from '$models';
import { findShippingType } from '$templates/basket-page/basket-helper';
import { useBasket, useSite } from '~/store';
import { Button } from '../button';
import { Popover } from '../popover';
import { Separator } from '../separator';
import { SvgIcon } from '../svg-icon';
import { Text } from '../text';
import { MiniBasketItem } from './mini-basket-item';

export const MiniBasket = () => {
    const { translate } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [anchorHover, setAnchorHover] = useState(false);
    const { basket, shouldOpenMiniBasket, clearOpenMinibasket } = useBasket();
    const router = useRouter();
    const { setSite } = useSite();
    const [shipping, setShipping] = useState<IIShippingType>();
    const theme = useTheme();

    useEffect(() => {
        const routeChangeHandler = () => setIsOpen(false);
        router.events.on('routeChangeStart', routeChangeHandler);

        return () => router.events.off('routeChangeStart', routeChangeHandler);
    }, []);

    useEffect(() => {
        if (shouldOpenMiniBasket) {
            setIsOpen(true);
        }
    }, [shouldOpenMiniBasket]);

    useEffect(() => {
        setSite({ headerHideable: !isOpen });
        if (!isOpen) {
            clearOpenMinibasket();
        }
    }, [isOpen]);

    useEffect(() => {
        setShipping(findShippingType(basket));
    }, [basket]);

    useEffect(() => {
        const timeout =
            anchorHover && router.asPath !== basketPageUrl ? setTimeout(() => setIsOpen(true), 1000) : undefined;
        return () => timeout && clearTimeout(timeout);
    }, [anchorHover]);

    const navigateToBasket = () => {
        router.push(basketPageUrl);
    };

    const shouldDisplayDefaultShipping = useMemo(() => {
        if (
            basket?.lines?.some((line) => line.b2CDeliveryMethod === B2CDeliveryMethod.DirectOnly) &&
            basket?.lines?.some((line) => line.b2CDeliveryMethod === B2CDeliveryMethod.ShopOnly)
        ) {
            return false;
        }
        return (
            basket?.lines?.filter(
                (line) =>
                    (line.b2CDeliveryMethod === B2CDeliveryMethod.DirectOnly ||
                        line.b2CDeliveryMethod === B2CDeliveryMethod.ShopOnly) &&
                    !line.isGiftShopItem
            ).length === basket?.lines?.length
        );
    }, [basket?.lines]);

    return (
        <Popover
            hPosition="right"
            open={isOpen}
            onClickOutside={() => setIsOpen(false)}
            anchor={
                <IconContent
                    onClick={navigateToBasket}
                    onMouseEnter={() => setAnchorHover(true)}
                    onMouseLeave={() => setAnchorHover(false)}
                >
                    <IconWrapper>
                        <SvgIcon size={theme.sizes.menuIcons} svg="shoppingBag" />
                        <AnimatePresence>
                            {!!basket?.lines?.length && (
                                <CountLabel
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ ease: 'easeOut', duration: 0.25 }}
                                >
                                    {basket?.lines?.reduce((sum, line) => sum + (line?.quantity || 0), 0)}
                                </CountLabel>
                            )}
                        </AnimatePresence>
                    </IconWrapper>
                    <span>{translate('header.miniBasket.miniBasketLabel')}</span>
                </IconContent>
            }
        >
            <StyledContent>
                {basket?.lines && (
                    <>
                        <MiniBasketHeader justifyContent="space-between" alignItems="center">
                            <Text h5 fontWeight="Medium">{`${translate(
                                'header.miniBasket.miniBasketLabel'
                            )} (${basket?.lines?.reduce((sum, line) => sum + (line.quantity || 0), 0)})`}</Text>
                            <CloseCTA variant="custom" onClick={() => setIsOpen(false)}>
                                <SvgIcon color={theme.colors.white} size={20} svg="cross" />
                            </CloseCTA>
                        </MiniBasketHeader>
                        <BasketLineContainer column>
                            {basket?.lines?.map((line) => (
                                <MiniBasketItem key={line?.product?.itemNumber} line={line} />
                            ))}
                        </BasketLineContainer>
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text>{translate('header.miniBasket.subtotalLabel')}</Text>
                            <Text fontWeight="Bold">{formatPrice(basket.totalExDiscount)}</Text>
                        </Flex>
                        {!!basket.discount && (
                            <Flex justifyContent="space-between" alignItems="center">
                                <Text color={theme.colors.red}>{translate('header.miniBasket.discountLabel')}</Text>
                                <Text fontWeight="Bold" color={theme.colors.red}>
                                    {`- ${formatPrice(basket.discount)}`}
                                </Text>
                            </Flex>
                        )}
                        {!!basket.lines.length && !!basket.lines.filter((line) => !line.isGiftShopItem).length && (
                            <>
                                {!basket.shippingKey && !shipping?.downloadOnly ? (
                                    <Flex justifyContent="space-between" alignItems="center">
                                        <Text>{translate('header.miniBasket.defaultDeliveryLabel')}</Text>
                                        <Text fontWeight="Bold">
                                            {shipping
                                                ? formatPrice(shipping.calculatedShippingPrice)
                                                : formatPrice(basket.shipping)}
                                        </Text>
                                    </Flex>
                                ) : (
                                    <Flex justifyContent="space-between" alignItems="center">
                                        {shouldDisplayDefaultShipping ? (
                                            <Text>{translate('header.miniBasket.defaultDeliveryLabel')}</Text>
                                        ) : (
                                            <Text>{`${translate('header.miniBasket.deliveryLabel')}${
                                                shipping?.name ? ` (${shipping.name})` : ''
                                            }`}</Text>
                                        )}
                                        <Text fontWeight="Bold">
                                            {shipping
                                                ? formatPrice(shipping.calculatedShippingPrice)
                                                : formatPrice(basket.shipping)}
                                        </Text>
                                    </Flex>
                                )}
                            </>
                        )}
                        {!PRODUCTION && basket.sameDayDeliveryDetails && (
                            <Flex justifyContent="space-between" alignItems="center">
                                <Text>{translate('header.miniBasket.giftShopDeliveryTitleLabel')}</Text>
                                <Text>
                                    {formatPrice(basket.sameDayDeliveryDetails.shippingType?.calculatedShippingPrice)}
                                </Text>
                            </Flex>
                        )}
                        <Separator />
                        <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            spacingBottom="medium"
                            gap="extrasmall"
                        >
                            <Text fontWeight="Bold" variant="bodyLarge">
                                {translate('header.miniBasket.priceTotalLabel')}
                            </Text>
                            <Text fontWeight="Bold" variant="bodyLarge">
                                {shipping
                                    ? formatPrice((shipping?.calculatedShippingPrice || 0) + (basket?.totalIncVAT || 0))
                                    : formatPrice(basket.totalIncVAT)}
                            </Text>
                        </Flex>
                        {/* Hide basket link in basket and checkout */}
                        {router.pathname !== basketPageUrl && router.pathname !== checkoutPageUrl && (
                            <Button variant="primary" width="expansive" onClick={navigateToBasket}>
                                {translate('header.miniBasket.showBasketButton')}
                            </Button>
                        )}
                        {!!shipping?.shippingPriceLimit &&
                            !!basket?.totalIncVAT &&
                            basket?.totalIncVAT < shipping?.shippingPriceLimit && (
                                <FreeShippingContainer>
                                    {`${formatString(
                                        translate('checkout.checkoutBasket.deliveryFreeHeader'),
                                        formatPrice(shipping.shippingPriceLimit - basket.totalIncVAT) || ''
                                    )} `}
                                    <FreeShippingBoldText>
                                        {translate('checkout.checkoutBasket.deliveryFreeText')}
                                    </FreeShippingBoldText>
                                    {` ${translate('checkout.checkoutBasket.deliveryFreeSubHeader')}`}
                                </FreeShippingContainer>
                            )}
                    </>
                )}
            </StyledContent>
        </Popover>
    );
};

const StyledContent = styled.div(({ theme }) => ({
    width: '100vw',
    backgroundColor: theme.colors.white,
    padding: `${theme.space[8]} ${theme.space[7]} ${theme.space[7]}`,
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    maxWidth: theme.sizes.contentMaxWidth,

    [mq('md')]: {
        maxWidth: '500px',
    },
}));

const IconContent = styled.button(({ theme }) => ({
    all: 'unset',
    font: theme.fontFamilies.regular,
    fontWeight: theme.fontWeights.medium,
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.fontSizes.xxxs,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',

    [mq('frame')]: {
        fontSize: theme.fontSizes.sm,
        flexDirection: 'row',

        span: {
            marginLeft: theme.space[2],
        },
    },
}));

const CountLabel = styled(motion.div)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.colors.primaryTheme,
    borderRadius: '40px',
    color: theme.colors.white,
    display: 'flex',
    fontFamily: theme.fontFamilies.regular,
    fontWeight: theme.fontWeights.bold,
    fontSize: '10px',
    height: '18px',
    paddingLeft: '1px',
    justifyContent: 'center',
    lineHeight: theme.lineHeights.single,
    position: 'absolute',
    right: '-8px',
    top: '-5px',
    width: '18px',
}));

const BasketLineContainer = styled(Flex)(({ theme }) => ({
    maxHeight: '350px',
    overflowY: 'auto',
    paddingRight: theme.space[1],
    marginBottom: theme.space[3],
}));

const MiniBasketHeader = styled(Flex)(({ theme }) => ({
    paddingBottom: theme.space[3],
    borderBottom: theme.general.border,
}));

const IconWrapper = styled.div({
    display: 'flex',
    position: 'relative',
});

const CloseCTA = styled(Button)(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.colors.black,
    borderRadius: '100%',
    display: 'flex',
    height: '30px',
    justifyContent: 'center',
    width: '30px',
}));

const FreeShippingContainer = styled.div(({ theme }) => ({
    marginTop: theme.space[5],
    marginBottom: theme.space[2],
}));

const FreeShippingBoldText = styled.strong(({ theme }) => ({
    color: theme.colors.checkoutGreen,
    fontWeight: theme.fontWeights.ultraBold,
    textTransform: 'uppercase',
}));
