import React, { FC, memo } from 'react';
import { SearchFocusReturnProps } from '~/modules/main-navigation/components/shared/use-search-focus';
import {
    ICombinedHelloRetailProduct,
    IHelloRetailBlogPost,
    IHelloRetailCategory,
    IHelloRetailFilterSelections,
    IHelloRetailSearchWordSuggestion,
    IHelloRetailSitePage,
    IHelloRetailSortingOption,
} from '~/modules/search/interfaces/suggestion';
import { SuggestionContent, SuggestionInitialContent, SuggestionLoader, SuggestionNoResults } from './components';
import { SearchContentTypes } from '~/modules/search';
import { IIndeksRetailFacetOutput, ISearchSerieResult } from '$models';
import { HelloRetailFacetControls } from '~/modules/filter/components/hello-retail/hello-retail-facet-controls';
import { defaultHelloRetailSortOption } from '~/modules/filter/helpers';

type Variant = 'plain' | 'normal';

type SearchSuggestionsProps = {
    productResults?: ICombinedHelloRetailProduct[];
    articleResults?: IHelloRetailBlogPost[];
    storeResults?: IHelloRetailSitePage[];
    categoryResults?: IHelloRetailCategory[];
    searchWordResults?: IHelloRetailSearchWordSuggestion[];
    focusIndex?: number;
    sectionIndex?: number;
    variant?: Variant;
    pageSize?: number;
    isLoading?: boolean;
    initialContent?: ICombinedHelloRetailProduct[];
    initialContentTitle?: string;
    zeroResultContent?: ICombinedHelloRetailProduct[];
    zeroResultContentTitle?: string;
    isSearching?: boolean;
    searchTerm?: string;
    resultCount?: { [key in SearchContentTypes]?: number };
    filterPanelOpen?: boolean;
    setFilterPanelOpen?: (open: boolean) => void;
    selectedFacets?: IHelloRetailFilterSelections;
    facets?: IIndeksRetailFacetOutput[];
    sortingOptions?: IHelloRetailSortingOption[];
    closeOverlay?: () => void;
    onSetFacets?: (facets: IHelloRetailFilterSelections) => void;
    onLoadMore?: (type: SearchContentTypes, isLocal?: boolean) => void;
    loadingNextPage?: boolean;
    onResetSize?: () => void;
    serieResults?: ISearchSerieResult;
    onSetCurrentTab?: (tab: string) => void;
    withScrollRestoration?: boolean;
    hasActiveFacets?: boolean;
    initialTab?: string;
    isDesktop?: boolean;
    isSearchPage?: boolean;
    onMobileLinkSearch?: (searchQuery: string) => void;
} & Pick<SearchFocusReturnProps, 'setIndexes'>;

export const SearchSuggestions: FC<SearchSuggestionsProps> = memo((props: SearchSuggestionsProps) => {
    const {
        productResults,
        articleResults,
        storeResults,
        categoryResults,
        searchWordResults,
        focusIndex,
        sectionIndex,
        variant = 'normal',
        pageSize = 4,
        isLoading,
        initialContent,
        initialContentTitle,
        zeroResultContent,
        zeroResultContentTitle,
        isSearching,
        searchTerm,
        resultCount,
        facets,
        selectedFacets,
        sortingOptions,
        filterPanelOpen,
        setFilterPanelOpen,
        closeOverlay,
        setIndexes,
        onSetFacets,
        onLoadMore,
        onSetCurrentTab,
        loadingNextPage,
        onResetSize,
        serieResults,
        withScrollRestoration,
        hasActiveFacets,
        initialTab,
        isDesktop,
        isSearchPage,
        onMobileLinkSearch,
    } = props;

    const ref = React.createRef<HTMLDivElement>();

    // Loading state
    if ((!productResults?.length && !initialContent?.length) || (isSearching && !productResults?.length && isLoading)) {
        return <SuggestionLoader variant={variant} pageSize={pageSize} />;
    }

    // No results
    if (
        productResults?.length === 0 &&
        articleResults?.length === 0 &&
        categoryResults?.length === 0 &&
        !isLoading &&
        isSearching
    ) {
        return !!zeroResultContent?.length && !!zeroResultContentTitle ? (
            <SuggestionInitialContent
                focusIndex={focusIndex}
                initialContent={zeroResultContent}
                initialContentTitle={zeroResultContentTitle}
                sectionIndex={sectionIndex}
                setIndexes={setIndexes}
                variant={variant}
                onCloseOverlay={closeOverlay}
                isZeroResult
                isDesktop={isDesktop}
                isSearchPage={isSearchPage}
                onMobileLinkSearch={onMobileLinkSearch}
            />
        ) : (
            <SuggestionNoResults variant={variant} />
        );
    }

    // Initial content
    if (initialContent?.length && !isSearching) {
        return (
            <SuggestionInitialContent
                focusIndex={focusIndex}
                initialContent={initialContent}
                initialContentTitle={initialContentTitle}
                sectionIndex={sectionIndex}
                setIndexes={setIndexes}
                variant={variant}
                onCloseOverlay={closeOverlay}
                isDesktop={isDesktop}
                isSearchPage={isSearchPage}
                onMobileLinkSearch={onMobileLinkSearch}
            />
        );
    }

    return (
        <SuggestionContent
            ref={ref}
            variant={variant}
            articleResults={articleResults}
            focusIndex={focusIndex}
            productResults={productResults}
            categoryResults={categoryResults}
            sectionIndex={sectionIndex}
            setIndexes={setIndexes}
            storeResults={storeResults}
            searchWordResults={searchWordResults}
            searchTerm={searchTerm}
            resultCount={resultCount}
            onCloseOverlay={closeOverlay}
            onLoadMore={onLoadMore}
            isLoadingMore={loadingNextPage}
            onResetSize={onResetSize}
            serieResults={serieResults}
            onSetCurrentTab={onSetCurrentTab}
            withScrollRestoration={withScrollRestoration}
            hasActiveFacets={hasActiveFacets}
            resetFilters={() => {
                onSetFacets?.({ sorting: defaultHelloRetailSortOption });
                ref.current?.scrollTo(0, 0);
            }}
            initialTab={initialTab}
            isDesktop={isDesktop}
            isSearchPage={isSearchPage}
            onMobileLinkSearch={onMobileLinkSearch}
            productFacets={
                <HelloRetailFacetControls
                    facets={facets}
                    selectedFacets={selectedFacets}
                    sortingOptions={sortingOptions}
                    openOverwrite={filterPanelOpen}
                    onSetOpenOverwrite={setFilterPanelOpen}
                    hideOnMobile
                    hideOnDesktop
                    onConfirm={(facets) => {
                        onSetFacets?.(facets);
                        ref.current?.scrollTo(0, 0);
                    }}
                    onSelectFacet={(facets) => {
                        onSetFacets?.(facets);
                        ref.current?.scrollTo(0, 0);
                    }}
                    totalResults={resultCount?.products || 0}
                />
            }
        />
    );
});
