import React, { FC, memo } from 'react';
import {
    SearchSuggestionsMainContent,
    SearchSuggestionsSideContent,
    SearchSuggestionsWrapper,
    StyledListElement,
    StyledProductGrid,
    StyledSuggestionSection,
    StyledSuggetionTitle,
} from '../shared/styles';
import { SuggestionProduct } from '../suggestion-product';
import { ICombinedHelloRetailProduct } from '~/modules/search/interfaces/suggestion';
import { LatestSearch } from './lastest-search';
import { useTranslation } from '$hooks';

type Variant = 'plain' | 'normal';

type Props = {
    variant?: Variant;
    initialContentTitle?: string;
    initialContent?: ICombinedHelloRetailProduct[];
    focusIndex?: number;
    sectionIndex?: number;
    isZeroResult?: boolean;
    isDesktop?: boolean;
    isSearchPage?: boolean;
    setIndexes?: (sectionIndex: number, focusIndex: number) => void;
    onCloseOverlay?: () => void;
    onMobileLinkSearch?: (searchQuery: string) => void;
};

const renderedInitialContent = 20;

export const SuggestionInitialContent: FC<Props> = memo((props: Props) => {
    const {
        variant = 'normal',
        focusIndex,
        initialContent,
        initialContentTitle,
        sectionIndex,
        isZeroResult,
        isDesktop,
        isSearchPage,
        onCloseOverlay,
        setIndexes,
        onMobileLinkSearch,
    } = props;
    const { translate } = useTranslation();

    return (
        <SearchSuggestionsWrapper variant={variant}>
            <SearchSuggestionsMainContent>
                {isZeroResult && (
                    <ul>
                        <StyledListElement>{translate('search.autoSuggest.noResults')}</StyledListElement>
                    </ul>
                )}
                {!isZeroResult && (
                    <LatestSearch
                        onCloseOverlay={onCloseOverlay}
                        isDesktop={isDesktop}
                        isSearchPage={isSearchPage}
                        onLatestSearchMobileLink={onMobileLinkSearch}
                    />
                )}
                <StyledSuggestionSection>
                    <StyledSuggetionTitle size="16px" fontWeight="Medium" as="p">
                        {initialContentTitle}
                    </StyledSuggetionTitle>
                    <StyledProductGrid>
                        {initialContent?.slice(0, renderedInitialContent).map?.((suggestion, i) => (
                            <SuggestionProduct
                                key={suggestion?.itemNumber}
                                product={suggestion}
                                index={i}
                                focusIndex={focusIndex}
                                sectionIndex={sectionIndex}
                                setIndexes={setIndexes}
                            />
                        ))}
                    </StyledProductGrid>
                </StyledSuggestionSection>
            </SearchSuggestionsMainContent>
            <SearchSuggestionsSideContent />
        </SearchSuggestionsWrapper>
    );
});
