import React, { FC, memo } from 'react';
import { SearchSuggestionsWrapper, StyledListElement } from '../shared/styles';
import { useTranslation } from '$hooks';

type Variant = 'plain' | 'normal';

type Props = {
    variant?: Variant;
};

export const SuggestionNoResults: FC<Props> = memo((props: Props) => {
    const { variant = 'normal' } = props;
    const { translate } = useTranslation();

    return (
        <SearchSuggestionsWrapper variant={variant}>
            <ul>
                <StyledListElement>{translate('search.autoSuggest.noResults')}</StyledListElement>
            </ul>
        </SearchSuggestionsWrapper>
    );
});
