import React, { FC, memo } from 'react';
import { StyledListElement } from '../shared/styles';
import { ICombinedHelloRetailProduct } from '~/modules/search/interfaces/suggestion';
import { ProductCard } from '$components/elements/product-card';

type Props = {
    product: ICombinedHelloRetailProduct;
    index: number;
    sectionIndex?: number;
    focusIndex?: number;
    setIndexes?: (sectionIndex: number, focusIndex: number) => void;
    onClick?: () => void;
};

export const SuggestionProduct: FC<Props> = memo((props: Props) => {
    const { product, index, sectionIndex, focusIndex, setIndexes, onClick } = props;

    return (
        <StyledListElement
            key={product?.itemNumber}
            as="li"
            state={sectionIndex === 1 && focusIndex === index ? 'active' : ''}
            onFocus={() => setIndexes?.(index, 1)}
            onMouseEnter={() => setIndexes?.(index, 1)}
        >
            <ProductCard
                key={product?.itemNumber}
                {...product}
                forcedSpecialPrice={product?.hasSpecialPrice}
                onClickSideEffect={onClick}
            />
        </StyledListElement>
    );
});
