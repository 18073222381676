import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { useSearchHistory } from '~/modules/search/hooks/use-search-history.hook';
import { StyledLink, StyledListElement, StyledSuggestionSection, StyledSuggetionTexts } from '../../shared/styles';
import { useTranslation } from '$hooks';

import { SvgIcon } from '$components/elements/svg-icon';
import { Text } from '$components/elements/text';
import { ellipsis } from '$lib/style-helpers';
import { RouteKeys, useCMSRoute } from '~/store';
import { Flex } from '$components/layouts';
import { Button } from '$components/elements/button';
import { FILTER_KEY_SEARCH } from '~/modules/filter/helpers';

type Props = {
    focusIndex?: number;
    sectionIndex?: number;
    isDesktop?: boolean;
    isSearchPage?: boolean;
    setIndexes?: (sectionIndex: number, focusIndex: number) => void;
    onCloseOverlay?: () => void;
    onLatestSearchMobileLink?: (searchQuery: string) => void;
};

export const LatestSearch: FC<Props> = memo((props: Props) => {
    const { focusIndex, sectionIndex, setIndexes, onCloseOverlay } = props;
    const { history, onClearHistory } = useSearchHistory();
    const { translate } = useTranslation();
    const { routes } = useCMSRoute();

    if (!history.length) {
        return <></>;
    }

    const baseUrl = routes[RouteKeys.Search] || '';

    function onMobileLinkSearch(event: React.MouseEvent<Element, MouseEvent>, searchQuery: string) {
        event.preventDefault();
        props?.onLatestSearchMobileLink?.(searchQuery);
    }

    return (
        <>
            <SuggestionContainer>
                <HeaderContainer justifyContent="space-between" alignItems="center">
                    <SuggetionTitle size="12px" fontWeight="Medium" as="p">
                        {translate('search.autoSuggest.latestSearch')}
                    </SuggetionTitle>
                    <StyledButton variant="link" onClick={onClearHistory}>
                        <SuggetionTitle size="12px" fontWeight="Medium" as="p">
                            {translate('search.autoSuggest.clearHistory')}
                        </SuggetionTitle>
                    </StyledButton>
                </HeaderContainer>
                <ul>
                    {history.map((search, i) => (
                        <SearchItem
                            onFocus={() => setIndexes?.(i, 2)}
                            onMouseEnter={() => setIndexes?.(i, 2)}
                            key={(search || '') + i}
                            state={sectionIndex === 2 && focusIndex === i ? 'active' : ''}
                        >
                            <SearchLink
                                href={
                                    baseUrl
                                        ? `${baseUrl}?${FILTER_KEY_SEARCH}=${encodeURIComponent(search)}`
                                        : undefined
                                }
                                onClick={(e) => {
                                    props?.isDesktop ? onCloseOverlay?.() : onMobileLinkSearch(e, search);
                                }}
                                replace={props?.isDesktop && props?.isSearchPage}
                            >
                                <SvgIcon size={22} svg="history" />
                                <StyledSuggetionTexts>
                                    <SuggestionText h6>{search}</SuggestionText>
                                </StyledSuggetionTexts>
                            </SearchLink>
                        </SearchItem>
                    ))}
                </ul>
            </SuggestionContainer>
            <Divider />
        </>
    );
});

const SuggestionContainer = styled(StyledSuggestionSection)(({ theme }) => ({
    marginBottom: theme.space[1],
}));

const HeaderContainer = styled(Flex)(({ theme }) => ({
    paddingTop: theme.space[5],
    paddingBottom: theme.space[2],
}));

const SuggetionTitle = styled(Text)({
    margin: 0,
});

const SuggestionText = styled(Text)(({ theme }) => ({
    margin: 0,
    ...theme.mixins.useTextStyle('bodySmall'),
    ...ellipsis,
}));

const Divider = styled.div(({ theme }) => ({
    width: '100%',
    marginTop: theme.space[3],
    borderBottom: `1px solid ${theme.colors.primaryGreyLight}`,
}));

const StyledButton = styled(Button)(({ theme: { colors } }) => ({
    color: colors.red,
    textDecoration: 'none',
}));

const SearchItem = styled(StyledListElement)(({ theme }) => ({
    display: 'flex',
    // Testing spacing
    padding: `${theme.space[2]} 0`,
}));

const SearchLink = styled(StyledLink)({
    flexBasis: 'auto',
    width: 'auto',
});
