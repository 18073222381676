import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Flex } from '$components/layouts';
import { Text } from '$components/elements/text';
import { Link } from '$components/elements/link';
import { Image } from '$components/elements/image';
import { trackHelloRetailClick } from '$services/hello-retail-tracking.service';

type ResultCardProps = {
    imageSrc?: string;
    fallbackImageSrc?: string;
    title?: string;
    teaser?: string;
    url?: string;
    trackingCode?: string;
    onClickSideEffect?: () => void;
};

export const SuggestionResultCard: FC<ResultCardProps> = ({
    imageSrc,
    title,
    teaser,
    url,
    trackingCode,
    fallbackImageSrc,
    onClickSideEffect,
}) => {
    const handleClick = async (source?: string) => {
        onClickSideEffect?.();
        if (!source) {
            return;
        }
        trackHelloRetailClick(source);
    };
    return (
        <Link href={url} onClick={() => handleClick(trackingCode)}>
            <Flex column gap="extrasmall">
                <ResultImageContainer>
                    {imageSrc && <Image height={140} src={imageSrc || ''} objectFit="cover" />}
                    {!imageSrc && <Image height={140} src={''} absoluteSrc={fallbackImageSrc} objectFit="cover" />}
                </ResultImageContainer>
                <Text font="regular" fontWeight="Bold">
                    {title}
                </Text>
                <Text>{teaser}</Text>
            </Flex>
        </Link>
    );
};

const ResultImageContainer = styled.div(({ theme }) => ({
    background: theme.colors.grey5,
    height: 140,
    width: '100%',
    borderRadius: theme.general.borderRadius,
    overflow: 'hidden',
}));
