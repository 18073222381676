import { useEffect, useState } from 'react';
import { ICombinedHelloRetailProduct } from '~/modules/search/interfaces/suggestion';
import { mapHelloRetailToIndeksRetailProduct } from '$lib/helpers/hello-retail.helper';
import { getSuggestionInitialContentUrl } from '$services/search';

type Props = {
    overlayOpen?: boolean;
};

export const useSuggestionsInitialContent = ({ overlayOpen }: Props) => {
    const [data, setData] = useState<ICombinedHelloRetailProduct[]>();
    const [title, setTitle] = useState<string>();
    const [fetched, setFetched] = useState(false);

    useEffect(() => {
        if (!fetched && overlayOpen) {
            setFetched(true);
            fetchInitialContent();
        }
    }, [fetched, overlayOpen]);

    const fetchInitialContent = async () => {
        const response = await fetch(getSuggestionInitialContentUrl());
        if (response?.ok) {
            const responseData = await response.json();

            setData(mapHelloRetailToIndeksRetailProduct(responseData?.products?.initialContent?.[0]?.products, true));
            setTitle(responseData?.products?.initialContent?.[0]?.title);
        }
    };

    return { data, title };
};
