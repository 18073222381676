import React, { useEffect, useRef, FocusEvent, useState, useImperativeHandle, forwardRef } from 'react';
import styled from '@emotion/styled';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import { switchProp } from 'styled-tools';
import { useTranslation } from '$hooks';
import { mq } from '$lib/helpers';
import { SvgIcon } from '../svg-icon';

type Variant = 'plain' | 'normal' | 'mobile';

type SearchFieldProps = {
    searchTerm?: string;
    focus?: boolean;
    variant?: Variant;
    onlyVisual?: boolean;
    isMobile?: boolean;
    onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
    onChange?: (value: string) => void;
    onKeyDown?: (e: React.KeyboardEvent) => void;
    onSubmit?: () => void;
    onReset?: () => void;
    onMobileEnter?: () => void;
};

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>((props: SearchFieldProps, forwardedRef) => {
    const {
        focus,
        onFocus,
        searchTerm,
        onChange,
        onKeyDown,
        onSubmit,
        onReset,
        onMobileEnter,
        variant = 'normal',
        onlyVisual,
        isMobile,
    } = props;
    const { translate } = useTranslation();
    const [inFocus, setInFocus] = useState(false);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(forwardedRef, () => inputRef.current as HTMLInputElement);

    useEffect(() => {
        if (focus && inputRef.current) {
            inputRef.current?.focus();
        }
    }, [focus]);

    const keyDownHandler = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            inputRef.current?.blur();
            // I don't know why mobile keyboard doesn't close on blur, so this is a workaround
            if (isMobile) {
                buttonRef.current?.focus();
                onMobileEnter?.();
            }
        }
        onKeyDown?.(e);
    };

    return (
        <SearchFieldForm>
            <SearchInputWrapper>
                {(variant === 'normal' || variant === 'mobile') && (
                    <SearchIconButton
                        type="submit"
                        ref={buttonRef}
                        inFocus={onlyVisual ? false : inFocus || !!searchTerm?.length}
                        {...(isMobile || onlyVisual ? { onTouchStart: onSubmit } : { onMouseDown: onSubmit })}
                    >
                        <AccessibleIcon.Root label={translate('search.headerSearch.searchButtonAccessibilityLabel')}>
                            <SvgIcon svg="loupe" />
                        </AccessibleIcon.Root>
                    </SearchIconButton>
                )}

                <SearchInput
                    inFocus={onlyVisual ? false : inFocus || !!searchTerm?.length}
                    variant={variant}
                    ref={inputRef}
                    value={searchTerm}
                    onChange={({ target }) => onChange && onChange(target.value)}
                    onFocus={(event) => {
                        onFocus?.(event);
                        setInFocus(true);
                    }}
                    onBlur={() => {
                        setInFocus(false);
                    }}
                    onKeyDown={keyDownHandler}
                    placeholder={translate('search.headerSearch.plcHeaderSearch')}
                />
                {searchTerm && <Divider />}
                <ClearSearchButton onMouseDown={onReset} onTouchEnd={onReset} type="button" disabled={!searchTerm}>
                    <AccessibleIcon.Root label={translate('search.headerSearch.deleteButtonAccessibilityLabel')}>
                        <SvgIcon svg="cross" />
                    </AccessibleIcon.Root>
                </ClearSearchButton>
            </SearchInputWrapper>
        </SearchFieldForm>
    );
});

const SearchFieldForm = styled.div(({ theme }) => ({
    position: 'relative',
    zIndex: theme.zIndices.popover,

    width: '100%',
    margin: 'auto',
    padding: `${theme.space[2]} 0`,

    [mq('frame')]: {
        padding: '0',
    },
}));

const SearchInputWrapper = styled.div({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
});

const SearchInput = styled.input<{ variant: Variant; inFocus?: boolean }>(
    ({ theme: { sizes, fontSizes, colors, fontFamilies } }) => ({
        flex: '1',
        height: sizes.searchInputHeightMobile,
        border: 'none',

        fontFamily: fontFamilies.regular,
        background: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        '&:focus': {
            outline: 'none',
        },
        '::placeholder': {
            fontSize: fontSizes.sm,
            color: colors.grey70,
        },
        '&:focus::placeholder': {
            color: 'transparent',
        },
        [mq('frame')]: {
            fontSize: fontSizes.default,
            height: sizes.searchInputHeightDesktop,
            '::placeholder': {
                fontSize: sizes.input,
            },
        },
        [mq(0, 'md')]: {
            // important for iOS, otherwise iOS will zoom in everytime you click on the input field
            fontSize: fontSizes.default,
        },
    }),
    switchProp('variant', {
        normal: ({ inFocus, theme: { colors, space } }) => ({
            background: colors.grey5,
            border: `2px solid ${colors.grey5}`,
            borderRadius: '25px',
            paddingLeft: inFocus ? space[6] : 50,
            transition: 'all 200ms ease',

            ':focus': {
                background: colors.white,
                border: `2px solid ${colors.black}`,
            },
        }),
        mobile: ({ inFocus, theme: { colors, space } }) => ({
            border: `2px solid ${colors.black}`,
            borderRadius: '25px',
            paddingLeft: inFocus ? space[6] : 50,
            transition: 'all 200ms ease',

            ':focus': {
                background: colors.white,
                border: `2px solid ${colors.black}`,
            },
        }),
    })
);

const SearchIconButton = styled.button<{ disabled?: boolean; inFocus?: boolean }>(
    ({ inFocus, theme: { space, fontSizes } }) => ({
        position: 'absolute',
        top: '50%',
        ...(inFocus
            ? {
                  right: space[4],
              }
            : {
                  left: space[4],
              }),
        transform: 'translateY(-50%)',
        zIndex: 2,
        display: 'flex',
        justifyContent: 'center',
        padding: 0,
        fontSize: fontSizes.lg,
        border: 'none',
        background: 'none',
        cursor: 'pointer',

        transition: 'opacity 200ms ease',
    }),
    switchProp('disabled', {
        true: {
            opacity: 0,
            pointerEvents: 'none',
        },
        false: {
            opacity: 1,
            pointerEvents: 'all',
        },
    })
);

const Divider = styled.div(({ theme }) => ({
    width: 1,
    height: 20,
    background: theme.colors.borderColor,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: `calc(${theme.space[8]} + 8px)`,
}));

const ClearSearchButton = styled(SearchIconButton)(({ theme }) => ({
    left: 'unset',
    right: `calc(${theme.space[8]} + 8px)`,
    marginRight: 8,
}));
