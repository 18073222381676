import React from 'react';
import styled from '@emotion/styled';
import { Button } from '$components/elements/button';
import { Flex } from '$components/layouts';
import { mq } from '$lib/helpers';
import { useTranslation } from '$hooks';

export type suggestionTab = 'products' | 'categories' | 'articles';

type Props = {
    selectedTab: suggestionTab;
    setSelectedTab: (tab: suggestionTab) => void;
};

export const SuggestionTabs = ({ selectedTab, setSelectedTab }: Props) => {
    const { translate } = useTranslation();
    return (
        <TabContainer>
            <TabButton
                variant="inverted"
                selected={selectedTab === 'products'}
                onClick={() => setSelectedTab('products')}
            >
                {translate('search.headerSearch.sectionHeadlines.products')}
            </TabButton>
            <TabButton
                variant="inverted"
                selected={selectedTab === 'categories'}
                onClick={() => setSelectedTab('categories')}
            >
                {translate('search.headerSearch.sectionHeadlines.categories')}
            </TabButton>
            <TabButton
                variant="inverted"
                selected={selectedTab === 'articles'}
                onClick={() => setSelectedTab('articles')}
            >
                {translate('search.headerSearch.sectionHeadlines.articles')}
            </TabButton>
        </TabContainer>
    );
};

const TabContainer = styled(Flex)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'space-around',
    borderBottom: theme.general.border,
    paddingBottom: theme.space[2],
    padding: `${theme.space[2]} ${theme.space[6]}`,
    [mq('md')]: {
        justifyContent: 'center',
        gap: theme.space[11],
    },
}));

const TabButton = styled(Button)<{ selected?: boolean }>(({ selected, theme }) => ({
    fontWeight: selected ? theme.fontWeights.bold : theme.fontWeights.normal,
    padding: theme.space[1],
    height: 'auto',
    ...(!selected && {
        color: theme.colors.textDefault,
        borderColor: theme.colors.textMuted,
        '&:active': {
            color: theme.colors.textDefault,
            borderColor: theme.colors.textMuted,
        },
    }),
}));
