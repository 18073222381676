import { useMemo, useState } from 'react';
import {
    IHelloRetailFilterSelections,
    IHelloRetailSelectedFacet,
    IHelloRetailSortingOption,
} from '../interfaces/suggestion';
import { FilterControlTypes, defaultHelloRetailSortOption } from '~/modules/filter/helpers';
import { IIndeksRetailFacetOutput } from '$models';

type Props = {
    facets?: IIndeksRetailFacetOutput[];
    sortingOptions?: IHelloRetailSortingOption[];
};

export const useSearchSuggestionFilter = ({ facets, sortingOptions }: Props) => {
    const [facetPanelOpen, setFacetPanelOpen] = useState(false);

    const selectedFacets = useMemo(() => {
        const selected: IHelloRetailFilterSelections = {};
        const selectedFacets: IHelloRetailSelectedFacet[] = [];
        facets?.forEach((facet) => {
            if (facet.control === FilterControlTypes.Slider && facet.isSelected) {
                if (facet.currentMin !== undefined && facet.currentMax !== undefined) {
                    selectedFacets.push({
                        key: `${facet.key}`,
                        query: `${facet.key}:${facet.currentMin},${facet.currentMax}`,
                        title: facet.name,
                    });
                }
                return;
            }
            const selectedFacetList = facet.facetResults?.filter((fr) => fr.isSelected);
            if (selectedFacetList) {
                selectedFacetList.forEach((selectedFacet) => {
                    if (selectedFacet.query?.name && selectedFacet.query?.value) {
                        selectedFacets.push({
                            key: selectedFacet.query.value,
                            query: selectedFacet.query.value,
                            title: selectedFacet.query.name,
                        });
                    }
                });
            }
        });
        if (selectedFacets.length > 0) {
            selected.selectedFacets = selectedFacets;
        }
        return selected;
    }, [facets]);

    const sortingOptionsWithDefault: IHelloRetailSortingOption[] = useMemo(() => {
        const optionSelected = sortingOptions?.some((option) => option.selected);
        return [
            { ...defaultHelloRetailSortOption, selected: optionSelected ? false : true },
            ...(sortingOptions || []),
        ];
    }, [sortingOptions]);

    return { selectedFacets, sortingOptionsWithDefault, facetPanelOpen, setFacetPanelOpen };
};
